import { Injectable, computed, signal } from '@angular/core';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class RefererService {
  private url = signal(new URL(window.location.href));
  private pathName = computed(() => this.url().pathname.toLowerCase());
  private qp = computed(() => {
    const qp = {};
    new URLSearchParams(this.url().search).forEach((value, key) => {
      qp[key] = value;
    });
    return qp;
  });
  private referer = computed(() => this.qp()['referer'] || '');

  constructor(private ls: LocalstorageService) {}

  /**
   * Initializes the referer service by storing the referer information in local storage.
   * If a referer query parameter is present, it stores it.
   * If the path includes 'draws/locator' or 'draws/draw', it stores a predefined referer.
   * If no referer is found, it checks the document referrer for Google and stores 'GOOGLE' if found.
   */
  init() {
    const store = referer => {
      const payload = { referer: referer, exp: Date.now() + 1000 * 60 * 60 * 24 };
      this.ls.referral = payload;
    };

    if (this.referer()) {
      store(this.referer());
    } else if (this.pathName().includes('draws/locator')) {
      store('DRAWS-LOCATOR');
    } else if (this.pathName().includes('draws/draw')) {
      store('DRAWS-DRAW');
    } else {
      const referer = window.localStorage.getItem('REFERRAL_KEY');
      const parsed = JSON.parse(referer || '{}');
      if (!parsed.exp || parsed.exp < Date.now()) {
        const isGoogle = str => str?.indexOf('.google.') > -1;
        if (isGoogle(document.referrer)) {
          store('GOOGLE');
        }
      }
    }
  }
}
