import { computed, Injectable, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { PusherUserService } from './pusher-user.service';
import { map, of, switchMap, tap } from 'rxjs';
import { UserService } from '../account/user.service';
import { APP, IUser, LANGS_ENUM } from '@libs/interfaces';
import { LangService } from '../lang.service';
import { AwakeService } from '../awake.service';
import { UpdateService } from '../app.updates.service';
import { DeviceService } from '../device.service';
import { ResourcesService } from './resources.service';
import { RoutesService } from './routes.service';
import { RefererService } from './referer.service';
import { BalanceUserService } from '../wallet/balance-user.service';
import { IsValidRefreshToken } from '@libs/helper/tokens.helper';
import { LocalstorageService } from './localstorage.service';
import { BonusService } from './bonus.service';
import { LuckyCellsService } from './lucky-cells.service';
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private urlParams = signal(new URLSearchParams(window.location.search));
  params = computed(() => ({
    domain: window.location.origin,
    queryParamsStr: this.getCompleteUrl(),
    app: this.urlParams().get('app'),
    telegramMiniApp: this.urlParams().get('telegram_mini_app'),
    token: this.parseToken(),
    telegramUserId: this.urlParams().get('telegramUserId'),
    first_name: this.urlParams().get('first_name'),
    last_name: this.urlParams().get('last_name'),
    username: this.urlParams().get('username'),
    language_code: this.urlParams().get('language_code'),
    imageUrl: this.urlParams().get('imageUrl'),
    bonus: this.urlParams().get('bonus'),
  }));

  constructor(
    private auth: AuthService,
    private pusherUserService: PusherUserService,
    private userService: UserService,
    private resourcesService: ResourcesService,
    private langService: LangService,
    private awakeService: AwakeService,
    private update: UpdateService,
    private deviceService: DeviceService,
    private balanceUserService: BalanceUserService,
    private routesService: RoutesService,
    private refererService: RefererService,
    private ls: LocalstorageService,
    private bonusService: BonusService,
    private luckyCellsService: LuckyCellsService,
  ) {}

  /**
   * Initializes various services and sets up the application.
   * @returns An observable that emits when the resources are initialized.
   */
  init() {
    this.analizeQueryParams();
    this.refererService.init();
    this.deviceService.init();
    this.langService.init();
    this.langService.use(LANGS_ENUM.PT);
    this.awakeService.init();
    this.update.init();

    this.pusherUserService.onBlockedUser.subscribe((blocked: boolean) => blocked && this.auth.logout());
    return this.resourcesService.landing().pipe(switchMap(() => this.authInit()));
  }

  /**
   * Initializes authentication and loads resources based on the authentication state.
   * @returns An observable that emits true when the resources are loaded.
   */
  private authInit() {
    return this.auth.init().pipe(
      switchMap((logged: boolean) => (logged ? this.user() : this.notUser())),
      tap(() => this.routesService.onInit()),
      map(() => true),
    );
  }
  private notUser() {
    this.pusherUserService.init(null);
    return of(true);
  }
  /**
   * Fetches the user data and initializes the Pusher user service.
   * @returns An observable that emits the user data.
   */
  private user() {
    return this.userService.fetchUser().pipe(
      tap((user: IUser) => this.pusherUserService.init(user._id)),
      tap(() => this.balanceUserService.init()),
      switchMap(res => this.bonusService.checkFirstDepositBonus().pipe(map(() => res))),
      tap(() => this.luckyCells()),
    );
  }
  private luckyCells() {
    return this.luckyCellsService.init().subscribe();
  }
  private analizeQueryParams() {
    this.bonusService.onStartFirstDepositBonus(this.params().bonus);
    this.storeMiniAppFromQueryParams();
    this.storeAppFromQueryParams();
    this.getTokenFromQueryParams();
    this.getTelegramUserFromQueryParams();
  }

  /**
   * Gets the user_id from query parameters
   * @returns The user_id from query parameters or null if not present
   */
  private getTokenFromQueryParams() {
    const token = this.params().token;
    if (token) {
      if (IsValidRefreshToken(token)) {
        this.auth.storeRefreshToken(token);
        // Reload the page to apply the new token
        const domain = window.location.hostname;
        window.location.href = `https://${domain}/${this.params().app}?telegram_mini_app=true`;
      }
    }
  }

  storeMiniAppFromQueryParams() {
    this.deviceService.telegramMiniApp.set(Boolean(this.params().telegramMiniApp));
  }
  /**
   * Gets the app parameter from query parameters and stores it in local storage
   * @returns The app parameter from query parameters or null if not present
   */
  private storeAppFromQueryParams() {
    const app = this.params().app;

    if (app === APP.APP || app === APP.CASINO || app === APP.SPORTS) {
      this.ls.app = app;
    }
  }
  private getTelegramUserFromQueryParams() {
    if (this.params().telegramUserId) {
      this.deviceService.telegramUser.set({
        id: +this.params().telegramUserId,
        first_name: this.params().first_name.replace(/%20/g, ' '),
        last_name: this.params().last_name.replace(/%20/g, ' '),
        username: this.params().username,
        language_code: this.params().language_code,
        imageUrl: this.params().imageUrl || '',
      });
    }
  }
  private getCompleteUrl(): string {
    return `?${this.urlParams().toString()}`;
  }
  private parseToken(): string {
    if (this.urlParams().get('token1') && this.urlParams().get('token2') && this.urlParams().get('token3')) {
      return `${this.urlParams().get('token1')}.${this.urlParams().get('token2')}.${this.urlParams().get('token3')}`;
    }
    return null;
  }
}
