import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { of } from 'rxjs';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  firstRechargeBonus = 'xbonusRecharge';
  constructor(
    private apiService: ApiService,
    private ls: LocalstorageService,
  ) {}
  onStartFirstDepositBonus(bonus: string) {
    this.ls.removeIfExpiredBonusFirstDeposit();
    const ls_bonus = this.ls.bonusFirstDeposit;
    const firstDepositBonus = bonus === this.firstRechargeBonus;
    if (!ls_bonus && firstDepositBonus) {
      const exp = 1000 * 60 * 60 * 24 * 7;
      this.ls.storeBonusFirstDeposit({ code: this.firstRechargeBonus, expireAt: Date.now() + exp });
    }
  }
  checkFirstDepositBonus() {
    const ls_bonus = this.ls.bonusFirstDeposit;
    if (ls_bonus) {
      return this.apiService.post('bonus/landing/user/first-deposit/create', {});
    } else return of(null);
  }
}
