import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LangService } from './lang.service';
declare let process: any;

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private langService: LangService,
  ) {}

  /**
   * Initializes the update service by subscribing to version updates if in production environment.
   */
  init() {
    this.production && this.versionUpdates().subscribe();
  }

  /**
   * Listens for version updates and prompts the user to reload the page if an update is available.
   * @returns {Observable<{type: string, current: string, available: string}>} An observable that emits when a version update is available.
   */
  versionUpdates(): Observable<{ type: string; current: string; available: string }> {
    return this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })),
      switchMap(() => this.askReload()),
    );
  }

  /**
   * Asks the user to reload the page when a new version is available.
   * @returns {Observable<any>} An observable that emits the translated message.
   */
  askReload(): Observable<any> {
    return this.langService.translate$('LANDING.UNVED').pipe(
      tap((msg: any) => {
        if (confirm(msg)) document.location.reload();
      }),
    );
  }

  /**
   * Checks if the environment is production.
   * @returns {boolean} True if the environment is production, false otherwise.
   */
  get production(): boolean {
    return process.env.NODE_ENV === 'production';
  }
}
