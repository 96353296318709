import { computed, Injectable, signal } from '@angular/core';
import { Observable, switchMap, tap, timer } from 'rxjs';
import { ApiService } from '../api.service';
import { ITokens } from '@libs/interfaces';
import { AuthService } from '../landing/auth.service';
import { ConfigService } from '../landing/config.service';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { UserStoreService } from '../store/user-store.service';
import { isNil, omitBy } from 'lodash';
import { DeviceService } from '../device.service';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  botName = 'Lotodobicho_Bot';
  url = `https://t.me/${this.botName}`;
  telegramMiniApp = computed(() => this.deviceService.telegramMiniApp());
  startTimer = signal(Date.now());
  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private deviceService: DeviceService,
    private userStoreService: UserStoreService,
    private config: ConfigService,
  ) {
    setTimeout(() => {
      if (this.telegramMiniApp()) {
        timer(10000, 60000)
          .pipe(switchMap(() => this.registerMiniAppStats()))
          .subscribe();
      }
    }, 2000);
  }

  /**
   * Adds a Telegram provider for the user.
   * @param authData - The authentication data from Telegram.
   * @returns An Observable that emits the response from the API.
   */
  providerAdd(authData: any): Observable<any> {
    return this.apiService.post(`telegram/provider/add`, authData);
  }

  /**
   * Authenticates the user using Telegram credentials.
   * @param authData - The authentication data from Telegram.
   * @returns An Observable that emits the authentication result.
   */
  authenticateBy(authData: any): Observable<any> {
    return this.apiService.post(`telegram/provider/auth`, authData).pipe(
      MapResponseData,
      tap((tokens: ITokens) => this.auth.storeTokens(tokens)),
      switchMap(() => this.config.init()),
    );
  }
  goBot() {
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
      window.location.href = `intent://t.me/${this.botName}#Intent;package=org.telegram.messenger;scheme=tg;end`;
      return;
    }
    window.open(this.url, '_blank');
  }
  private registerMiniAppStats() {
    const payload = {
      logged: this.auth.logged(),
      connected: this.userStoreService.isTelegramProviderAdded(),
      startAt: this.startTimer(),
      now: Date.now(),
      user_id: this.auth.logged() ? this.userStoreService.user()?._id : null,
    };
    const cleanedPayload = omitBy(payload, isNil);
    return this.apiService.post(`telegram/bot/mini-app/stats`, cleanedPayload);
  }
}
